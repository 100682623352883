$(document).ready(function (e) {

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  //if ($(".showhide-filter__button").hasClass('active')) {
  //  $(".mobile-filter__showhide").slideDown(200);
  //}

  //$(".showhide-filter__button").click(function () {
  //  if ($(this).hasClass('active')) {
  //    $(this).removeClass('active');
  //    $(".mobile-filter__showhide").slideUp(200);
  //  } else {
  //    $(this).addClass('active');
  //    $(".mobile-filter__showhide").slideDown(200);
  //  }
  //});

});
