(function (window) {
    function Game() {
        var that = this;
        var _stage;

        // Animation Variables
        var BACKGROUND_SPEED = 1;
        var BACKGROUND_UP_SPEED = 0.5;
        var PROGRESS_SPEED = 1;
        var PROGRESS_LOOP_SPEED = 0.2;
        var PROGRESS_CURVE = 1.3;

        // Skin Color Variables
        var PROGRESS_COLOR = "#FFCC01";
        var DOT_COLOR_H = "#A9B5C1";
        var DOT_COLOR_V = "#FFCC01";
        var SHIP_IMAGE_URL = "assets/game-animation/assets/ship.png";

        var _progress = 70;
        var _progressScaleX;
        var _progressScaleY;

        var _shadowContainer = new createjs.Container();
        var _backgroundContainer = new createjs.Container();
        var _traceContainer = new createjs.Container();
        var _dotsContainer = new createjs.Container();

        var _tickerListener;

        this.load = function () {

            setupSkins();

            _stage = new createjs.Stage("stage");
            _stage.enableMouseOver();

            createjs.Ticker.setFPS(60);

            setupReferences();
            //onResize();
        };

        function start(progress) {
            PROGRESS_SPEED = 1;
            _progress = progress;
            // console.log("start");
            _traceContainer.visible = true;
            _tickerListener = createjs.Ticker.on("tick", tick);
        }

        function stop() {
            // console.log("stop " + _progress);
            stopTraceAnimation();
        }

        window.start = start;
        window.stop = stop;

        function reset(ev) {
            // console.log("reset ");
            createjs.Ticker.off("tick", _tickerListener);
            //_stage.update();

            _traceContainer.visible = false;
            _traceContainer.ship.blowAnim.stop();
            _traceContainer.trace.alpha = 1;
            _traceContainer.integral.alpha = 0.3;
            _traceContainer.ship.staticImage.alpha = 1;
            _traceContainer.ship.staticImage.filters = [];
            _traceContainer.ship.staticImage.updateCache();
            _traceContainer.ship.blowAnim.visible = false;

            _backgroundContainer.offsetX = 0;
            _backgroundContainer.offsetY = 0;

            _dotsContainer.verticalDots.offset = 0;
            _dotsContainer.horizontalDots.offset = 0;

        }

        function setupReferences() {
            setUpBackground();
            setUpShadows();
            setUpTrace();
            setUpDots();
            _stage.addChild(_backgroundContainer, _shadowContainer, _traceContainer, _dotsContainer);
        }

        this.onResize = function (width, height) {
            canvasElement.width = width;
            canvasElement.height = height;
            updateBackground();
            updateStaticElements();
            updateDots();
            if (canvasElement.width > canvasElement.height) {
                _progressScaleX = 1;
                _progressScaleY = (canvasElement.height - 90) / (canvasElement.width + 90);
            }
            else {
                _progressScaleX = (canvasElement.width + 90) / (canvasElement.height - 90);
                _progressScaleY = 1;
            }
            resizeShip();
            _stage.update();
        };

        function resizeShip() {
            var shipScale;

            shipScale = canvasElement.width / 1130;

            _traceContainer.ship.scaleX = shipScale;
            _traceContainer.ship.scaleY = shipScale;
        }

        function setUpTrace() {
            _traceContainer.ship = new createjs.Container().setTransform(0, 0, 0.9, 0.9, 0, 0, 0, 84, 89);
            _traceContainer.ship.staticImage = new createjs.Bitmap(SHIP_IMAGE_URL);
            _traceContainer.ship.staticImage.image.addEventListener("load", function () {
                _traceContainer.ship.staticImage.cache(0, 0, 184, 106);
            });
            _traceContainer.ship.blowAnim = new createjs.Sprite(new createjs.SpriteSheet({
                images: ["assets/game-animation/assets/blow_anim.png"],
                frames: { width: 400, height: 350 },
                animations: {
                    blow: [0, 44, "idle"],
                },
                framerate: 20
            })).setTransform(-135, -155, 1.2, 1.2);
            _traceContainer.ship.blowAnim.on("animationend", reset);
            _traceContainer.ship.blowAnim.visible = false;
            _traceContainer.ship.forwarding = true;
            _traceContainer.ship.addChild(_traceContainer.ship.staticImage, _traceContainer.ship.blowAnim);

            _traceContainer.trace = new createjs.Shape();
            _traceContainer.trace.graphics.setStrokeStyle(5);
            _traceContainer.trace.graphics.beginStroke(PROGRESS_COLOR);
            _traceContainer.trace.graphics.moveTo(27, canvasElement.height - 25);
            _traceContainer.trace.cmd = _traceContainer.trace.graphics.quadraticCurveTo(0, 0, 0, 0);
            _traceContainer.visible = false;

            _traceContainer.integral = new createjs.Shape();
            _traceContainer.integral.alpha = 0.3;
            _traceContainer.integral.graphics.beginFill(PROGRESS_COLOR);
            _traceContainer.integral.graphics.moveTo(27, canvasElement.height - 25);
            _traceContainer.integral.cmd = _traceContainer.integral.graphics.quadraticCurveTo(0, 0, 0, 0);
            _traceContainer.integral.graphics.lineTo(500, 500);

            //_traceContainer.tmp = new createjs.Bitmap("assets/target.png").setTransform(0, 0, 0.05, 0.05, 0, 0, 0, 400, 400);
            _traceContainer.addChild(_traceContainer.trace, _traceContainer.integral, _traceContainer.ship);
        }

        function updateTrace() {
            if (_traceContainer.ship.forwarding) {
                _progress += PROGRESS_SPEED;
                _traceContainer.ship.x = 25 + _progress;

                if (((_traceContainer.ship.x + (190 * _traceContainer.ship.scaleX)) / canvasElement.width) > 1) {
                    _traceContainer.ship.forwarding = false;
                    if (PROGRESS_SPEED != PROGRESS_LOOP_SPEED) {
                        PROGRESS_SPEED = PROGRESS_LOOP_SPEED;
                    }
                }
            }
            else {

                _progress -= PROGRESS_SPEED;
                _traceContainer.ship.x = 25 + _progress;
                if (((_traceContainer.ship.x + (190 * _traceContainer.ship.scaleX)) / canvasElement.width) < 0.97) {
                    _traceContainer.ship.forwarding = true;
                }
            }
            _traceContainer.ship.y = canvasElement.height - 25 - (_progress * _progressScaleY);

            _traceContainer.ship.cpx = (_traceContainer.ship.x - 25) / PROGRESS_CURVE + 25;
            _traceContainer.ship.cpy = _traceContainer.ship.y + (canvasElement.height - _traceContainer.ship.y - 25) / PROGRESS_CURVE;

            _traceContainer.trace.cmd._activeInstructions[1].x = _traceContainer.ship.x;
            _traceContainer.trace.cmd._activeInstructions[1].y = _traceContainer.ship.y;
            _traceContainer.trace.cmd._activeInstructions[1].cpx = _traceContainer.ship.cpx;
            _traceContainer.trace.cmd._activeInstructions[1].cpy = _traceContainer.ship.cpy;

            _traceContainer.integral.cmd._activeInstructions[0].y = canvasElement.height - 27;
            _traceContainer.integral.cmd._activeInstructions[1].x = _traceContainer.ship.x;
            _traceContainer.integral.cmd._activeInstructions[1].y = _traceContainer.ship.y;
            _traceContainer.integral.cmd._activeInstructions[1].cpx = _traceContainer.ship.cpx;
            _traceContainer.integral.cmd._activeInstructions[1].cpy = _traceContainer.ship.cpy;
            _traceContainer.integral.cmd._activeInstructions[2].x = _traceContainer.ship.x;
            _traceContainer.integral.cmd._activeInstructions[2].y = canvasElement.height - 27;

        }

        function stopTraceAnimation() {
            _traceContainer.ship.blowAnim.visible = true;
            _traceContainer.ship.staticImage.filters = [new createjs.ColorFilter(0, 0, 0, 1, 210, 21, 52, 0)];
            _traceContainer.ship.staticImage.updateCache();
            _traceContainer.ship.blowAnim.gotoAndPlay("blow");


            createjs.Tween.get(_traceContainer.trace).to({ alpha: 0 }, 700);
            createjs.Tween.get(_traceContainer.integral).to({ alpha: 0 }, 700);
            createjs.Tween.get(_traceContainer.ship.staticImage).to({ alpha: 0 }, 700);

        }

        function setUpBackground() {
            _backgroundContainer.offsetX = 0;
            _backgroundContainer.offsetY = 0;
            _backgroundContainer.first = new createjs.Bitmap("assets/game-animation/assets/background.jpg");
            _backgroundContainer.second = new createjs.Bitmap("assets/game-animation/assets/background.jpg");
            _backgroundContainer.first.image.addEventListener('load', function () {
                _backgroundContainer.addChild(_backgroundContainer.first, _backgroundContainer.second);
                updateBackground();
                _stage.update();
            });
        }

        function updateBackground() {

            _backgroundContainer.offsetX = _backgroundContainer.offsetX > 2719 ? _backgroundContainer.offsetX - 2719 + BACKGROUND_SPEED : _backgroundContainer.offsetX + BACKGROUND_SPEED;
            _backgroundContainer.offsetY = _backgroundContainer.offsetY < 2379 - canvasElement.height ? _backgroundContainer.offsetY + BACKGROUND_UP_SPEED : _backgroundContainer.offsetY;

            _backgroundContainer.first.x = -_backgroundContainer.offsetX;
            _backgroundContainer.second.x = 2719 - _backgroundContainer.offsetX;


            _backgroundContainer.first.y = canvasElement.height - 2379 + _backgroundContainer.offsetY;
            _backgroundContainer.second.y = _backgroundContainer.first.y;

            _backgroundContainer.cache(0, 0, canvasElement.width, canvasElement.height);
            _backgroundContainer.updateCache();
        }

        function setUpShadows() {
            _shadowContainer.shadowTop = new createjs.Bitmap("assets/game-animation/assets/shadow_mask.png");
            _shadowContainer.shadowBottom = new createjs.Bitmap("assets/game-animation/assets/shadow_mask.png");
            _shadowContainer.shadowLeft = new createjs.Bitmap("assets/game-animation/assets/shadow_mask.png");
            _shadowContainer.shadowRight = new createjs.Bitmap("assets/game-animation/assets/shadow_mask.png");
            _shadowContainer.shadowTop.image.addEventListener('load', updateStaticElements);

            _shadowContainer.xAxis = new createjs.Shape();
            _shadowContainer.xAxis.graphics.setStrokeStyle(3);
            _shadowContainer.xAxis.graphics.beginStroke("#7D849E");
            _shadowContainer.xAxis.cmd = _shadowContainer.xAxis.graphics.moveTo(25, canvasElement.height - 40);
            _shadowContainer.xAxis.graphics.lineTo(25, 0);

            _shadowContainer.yAxis = new createjs.Shape();
            _shadowContainer.yAxis.graphics.setStrokeStyle(3);
            _shadowContainer.yAxis.graphics.beginStroke("#7D849E");
            _shadowContainer.yAxis.cmd = _shadowContainer.yAxis.graphics.moveTo(24, canvasElement.height - 40);
            _shadowContainer.yAxis.graphics.lineTo(90, canvasElement.width - 40);

            _shadowContainer.addChild(_shadowContainer.shadowBottom, _shadowContainer.shadowLeft, _shadowContainer.xAxis, _shadowContainer.yAxis, _shadowContainer.shadowRight, _shadowContainer.shadowTop);
        }

        function updateStaticElements() {
            _shadowContainer.shadowTop.setTransform(0, 0, canvasElement.width, 1, 0, 180, 0, 0, 169);
            _shadowContainer.shadowBottom.setTransform(0, canvasElement.height - 169, canvasElement.width, 1, 0, 0, 0, 0, 0);
            _shadowContainer.shadowLeft.setTransform(169, 0, canvasElement.height, 1, 0, 0, 0, 0, 0);
            _shadowContainer.shadowLeft.rotation = 90;
            _shadowContainer.shadowRight.setTransform(canvasElement.width - 169, canvasElement.height, canvasElement.height, 1, 0, 0, 0, 0, 0);
            _shadowContainer.shadowRight.rotation = -90;

            _shadowContainer.xAxis.cmd._activeInstructions[0].y = canvasElement.height - 25;
            _shadowContainer.yAxis.cmd._activeInstructions[0].y = canvasElement.height - 25;
            _shadowContainer.yAxis.cmd._activeInstructions[1].x = canvasElement.width;
            _shadowContainer.yAxis.cmd._activeInstructions[1].y = canvasElement.height - 25;

            _traceContainer.trace.cmd._activeInstructions[0].y = canvasElement.height - 27;

            _shadowContainer.cache(0, 0, canvasElement.width, canvasElement.height);
            _shadowContainer.updateCache();

            _dotsContainer.verticalDots.y = canvasElement.height - 1630;
            _dotsContainer.verticalDots.mask.y = canvasElement.height - 1088;

            _dotsContainer.horizontalDots.y = canvasElement.height - 8;
            _dotsContainer.horizontalDots.mask.y = _dotsContainer.horizontalDots.y;
        }

        function setUpDots() {
            _dotsContainer.verticalDots = new createjs.Container().setTransform(10);
            _dotsContainer.verticalDots.offset = 0;
            _dotsContainer.verticalDots.mask = new createjs.Shape(new createjs.Graphics().f("#000").dr(5, 0, 8, 1080));


            _dotsContainer.horizontalDots = new createjs.Container().setTransform(90, canvasElement.height - 50);
            _dotsContainer.horizontalDots.offset = 0;
            _dotsContainer.horizontalDots.mask = new createjs.Shape(new createjs.Graphics().f("#000").dr(10, -5, 1920, 8));

            for (i = 0; i < 15; i++) {
                var dot = new createjs.Shape(new createjs.Graphics().f(DOT_COLOR_H).dc(i * 110, 0, 3));
                _dotsContainer.horizontalDots.addChild(dot);

                var dot2 = new createjs.Shape(new createjs.Graphics().f(DOT_COLOR_V).dc(0, i * 110, 3));
                _dotsContainer.verticalDots.addChild(dot2);

            }

            _dotsContainer.addChild(_dotsContainer.verticalDots, _dotsContainer.horizontalDots);
        }

        function updateDots() {
            _dotsContainer.horizontalDots.offset = _dotsContainer.horizontalDots.offset > 109 ? 0 : _dotsContainer.horizontalDots.offset + 1;
            _dotsContainer.horizontalDots.x = 90 - _dotsContainer.horizontalDots.offset;

            _dotsContainer.verticalDots.offset = _dotsContainer.verticalDots.offset > 109 ? 0 : _dotsContainer.verticalDots.offset + 1;
            _dotsContainer.verticalDots.y = canvasElement.height - 1630 + _dotsContainer.verticalDots.offset;

        }

        function tick(event) {
            updateBackground();
            updateTrace();
            updateDots();
            _stage.update(event);
        }

       function setupSkins() {
            const configsColor = getComputedStyle(document.body).getPropertyValue('--theme-color');
            const configsImg = document.body.getAttribute('data-configs-image-url');

            let progressColor = configsColor;
            let dh = configsColor;
            let dv = configsColor;
            let shipURL = configsImg;

            progressColor && (PROGRESS_COLOR = progressColor);
            dh && (DOT_COLOR_H = dh);
            dv && (DOT_COLOR_V = dh);
            shipURL && (SHIP_IMAGE_URL = shipURL);
        }
    }

    window.Game = Game;
}(window));

