function initFunction() {
    game_div = document.createElement('div');
    game_div.setAttribute('class', 'game');

    canvasElement = document.createElement("canvas");
    canvasElement.id = "stage";

    viewport_wrapper_div = document.createElement('div');
    viewport_wrapper_div.setAttribute('class', 'viewport-wrapper');
    game_div.appendChild(viewport_wrapper_div);

    viewport_div = document.createElement('div');
    viewport_div.setAttribute('id', 'viewport');
    viewport_div.style.webkitTransformOrigin = "0px 0px";
//resizeViewPort(viewport_div);
    viewport_div.appendChild(canvasElement);
    viewport_wrapper_div.appendChild(viewport_div);

    document.getElementById('desktop_landscape').appendChild(game_div);

    game = new Game();
    game.load();

    window['resizeGame'] = game.onResize;
}

window['initFunction'] = initFunction;


