let connection = {};
let error = {};

function connect(url, tokenValue) {
    const token = tokenValue;

    connection = $.connection(url);
    connection.qs = {"token": token};

    connection.received(function (data) {
        const message = JSON.parse(data);
        // if (message.command !== 'increase_bet')
        //     console.log(JSON.stringify(message) + ' - ' + new Date().toLocaleString() + ' - ' + Date.now());
        window.CommunicationManagerRef.service.messageReceived(message.command, message.data);
    });

    connection.start({transport: ["webSockets"]}).fail(ConnectionError);
    connection.disconnected(ConnectionError);

    function ConnectionError() {
        error.errorId = 1;
        error.messageText = 'connectionClosed';
        error.fatal = true;

        window.CommunicationManagerRef.service.messageReceived('error', error);

        connection.stop();
    }
}

function Send(command, data) {
    const sendObject = {};
    sendObject.command = command;
    sendObject.data = data;
    connection.send(JSON.stringify(sendObject));
}

